<template>
  <div>
    <ul class="nav nav-tabs control-sidebar-tabs">
      <li :title="$t('properties')" class="active settings-link">
        <router-link
          to="/dashboard/settings/preferences"
          data-toggle="tab"
          aria-expanded="true"
        >
          <div class="pull-left">
            <i class="fa fa-cogs"></i>
            {{ $t("preferences") }}
          </div>
          <div class="pull-right clicable">{{ $t("more") }}...</div>
        </router-link>
      </li>
    </ul>
    <div class="tab-content settings-form" data-testid="settings-form">
      <div class="tab-pane active" id="control-sidebar-0">
        <UserPreferencesForm />
      </div>
    </div>
  </div>
</template>

<script>
import UserPreferencesForm from "@/components/control-sidebar/property-editors/user-preferences-form.vue";

export default {
  name: "UserPreferencesSidebarPanel",
  components: {
    UserPreferencesForm
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .nav-tabs.control-sidebar-tabs {
    display: block;
  }
}
.settings-link {
  width: 100%;
  margin-bottom: 10px;
}

.settings-link > a {
  padding: 5px;
}

.settings-link > a > div {
  font-size: 12pt;
  font-weight: 600;
}

.skin-dark .settings-link > a > div {
  color: #b8c7ce;
}

.settings-link > a > div.clicable {
  color: #fff;
}

.settings-link > a > div:hover {
  opacity: 0.8;
  cursor: pointer;
  color: #fff;
}
</style>
