<template>
  <section>
    <PanelTopPropertiesContainer
      persistent="toogle_generic_panel"
      :panel="panel"
      @editGeneral="editGeneralPanelProperties"
      @editDetails="editSpecificPanelProperties"
    >
      <LinkedPanelForm
        v-if="isLinkedPanel"
        :template="template"
        :panelName="panelName"
      />
      <div v-if="canEditProperties">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group form-group-sm property">
              <div style="position: relative">
                <label for="">
                  {{ $t("title") }}
                  <Tooltip :title="$t('hints.panel_title')" />
                </label>
                <div style="position: absolute; top: 0; right: 0">
                  <span
                    class="clicable"
                    style="margin-right: 30px"
                    @click.stop.prevent="panelIframe = !panelIframe"
                    :title="$t('hints.show_in_iframe')"
                  >
                    <i
                      :class="panelIframe ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    ></i>
                    iframe
                  </span>
                  <span
                    class="clicable"
                    :title="$tc('icon', 1)"
                    @click.stop.prevent="panelIconEnabled = !panelIconEnabled"
                  >
                    <i
                      :class="
                        panelIconEnabled
                          ? 'fa fa-check-square-o'
                          : 'fa fa-square-o'
                      "
                    ></i>
                    {{ $tc("icon", 1) }}
                  </span>
                  <IconLibrary
                    v-if="panelIconEnabled"
                    :xs="true"
                    v-model="panelIcon"
                    style="display: inline-block; margin-left: 10px"
                  />
                </div>
              </div>
              <div style="clear: both">
                <JSONPathPicker
                  :entry="allowedJSONContext"
                  :append="true"
                  v-model="panelTitle"
                  :title="$t('hints.panel_title')"
                />
              </div>
              <div class="input-group input-group-sm">
                <span class="input-group-addon title-size" :title="$t('font_size')">
                  <label>{{ $t('size') }}</label>
                </span>
                <InputNumber
                  v-model="toolbarStyleFontSize"
                  class="form-control text-center"
                  :placeholder="$t('font_size')"
                  :title="$t('font_size')"
                />
                <span class="input-group-addon unit-size">pt</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group form-group-sm property">
              <!-- TODO: fitopage is only valid for columns with a single panel -->
              <label
                v-if="canFitOnPage"
                class="no-select clicable"
                for="fitToPage"
              >
                <input type="checkbox" v-model="fitToPage" id="fitToPage" />
                {{ $tc("titles.fit_to_page") }}
                <Tooltip :title="$t('hints.fit_to_page')" />
              </label>
              <label v-else>
                {{ $tc("height") }}
              </label>
              <div class="input-group input-group-sm" v-if="canBeProportional">
                <div class="input-group-addon title-size">
                  {{ $t("proportional") }}
                </div>
                <input
                  class="form-control text-center"
                  v-model="proportionalHeight"
                  ref="panelHeight"
                />
                <div class="input-group-addon unit-size">%</div>
              </div>
              <div class="input-group input-group-sm">
                <div class="input-group-addon" :class="classTitleSize">
                  <label>
                    {{ canFitOnPage ? $t("minimum_height") : $t("minimum") }}
                  </label>
                </div>
                <input
                  type="text"
                  class="form-control text-center"
                  v-model="minHeight"
                />
                <div class="input-group-addon unit-size">px</div>
              </div>
              <div class="input-group input-group-sm">
                <div class="input-group-addon" :class="classTitleSize">
                  <label>
                    {{ canFitOnPage ? $t("maximum_height") : $t("maximum") }}
                  </label>
                </div>
                <input
                  type="text"
                  class="form-control text-center"
                  v-model="maxHeight"
                />
                <div class="input-group-addon unit-size">px</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group form-group-sm property">
              <label class="no-select clicable">
                {{ $tc("scroll_bars") }}
              </label>
              <div class="input-group input-group-sm">
                <div class="input-group-addon input-group-addon-scroll">
                  <label>{{ $t("vertical") }}</label>
                </div>
                <select class="form-control" v-model="panelVerticalScroll">
                  <option value="auto">{{ $t("automatic") }}</option>
                  <!-- <option value="scroll">{{ $t("always") }}</option> -->
                  <option value="hidden">{{ $t("never") }}</option>
                  <!-- <option value="unset">{{ $t("standard") }}</option> -->
                </select>
              </div>
              <div class="input-group input-group-sm">
                <div class="input-group-addon input-group-addon-scroll">
                  <label>{{ $t("horizontal") }}</label>
                </div>
                <select class="form-control" v-model="panelHorizontalScroll">
                  <option value="auto">{{ $t("automatic") }}</option>
                  <!-- <option value="scroll">{{ $t("always") }}</option> -->
                  <option value="hidden">{{ $t("never") }}</option>
                  <!-- <option value="unset">{{ $t("standard") }}</option> -->
                </select>
              </div>
            </div>
            <div
              class="form-group form-group-sm property text-center"
              style="margin-bottom: 15px"
            >
              <button
                class="btn btn-default btn-sm"
                @click="onApply"
                style="display: none"
              >
                {{ $tc("apply_to_other_panels") }}
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label style="margin-bottom: 0; vertical-align: middle"
                >{{ $t("synoptic.background_color") }}:</label
              >
              <ColorPicker
                v-model="panelColor"
                v-bind:pickerStyle="{left: '0px'}"
                icon="background"
                style="display: inline-block; margin-left: 10px"
              />
            </div>
          </div>
        </div>
        <SelectMultipleProcessAreas
          v-model="panelProcessAreas"
          :tooltipTitle="$t('titles.panel_access_by_process_area')"
          style="margin-bottom: 10px"
        />
      </div>
    </PanelTopPropertiesContainer>
    <TogglePanel
      :title="$t('specific_properties')"
      persistent="toogle_specific_panel"
      v-if="contentPanelWidget && ready && !isLinkedPanel"
      :icon="{
        before: 'fa fa-sliders',
        collapse: 'fa-minus',
        expand: 'fa-plus'
      }"
    >
      <component :is="contentPanelWidget"></component>
    </TogglePanel>
  </section>
</template>

<script>
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ColorPicker from "@/components/editor/color-picker.vue";
import SelectMultipleProcessAreas from "@/components/select-multiple-process-areas.vue";
import Panels from "@/assets/dashboard/panels.json";
import PanelTopPropertiesContainer from "@/components/control-sidebar/property-editors/panel-top-properties-container.vue";
import LinkedPanelForm from "@/components/control-sidebar/property-editors/linked-panel-form.vue";
import Tooltip from "@/components/tooltip.vue";
import JSONPathPicker from "@/components/control-sidebar/property-editors/json-path-picker.vue";
import IconLibrary from "@/components/editor/icon-library.vue";
import InputNumber from "@/components/input-number.vue";
import {isLinkedPanel, isSyncEnabled} from "@/services/dashboard.js";
import { onlyNumbers } from "@/plugins/utils.js";

const defPanel = (templateName) =>
  Panels.find((panel) => panel.template.template == templateName) || null;

export default {
  name: "PanelForm",
  components: {
    TogglePanel,
    ColorPicker,
    SelectMultipleProcessAreas,
    PanelTopPropertiesContainer,
    LinkedPanelForm,
    Tooltip,
    JSONPathPicker,
    IconLibrary,
    InputNumber
  },
  data() {
    return {
      previous: {
        style: null,
        heightProportion: 0
      },
      contentPanelWidget: null,
      ready: false,
      title: "",
      icon: "",
      iframeTitle: false,
      iconEnabled: false
    };
  },
  computed: {
    mode() {
      //return this.$store.getters['dashboard/mode']||'viewer';
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    proportionalHeight: {
      set(value) {
        let p = parseInt(value);
        p = isNaN(p) ? 0 : p;
        if (p < 0) p = 0;
        if (p >= 0 && p <= 100) {
          let panel = JSON.parse(JSON.stringify(this?.panel || {}));
          panel.heightProportion = p ? p / 100 : 0;
          this.saveDraft(panel);
          this.$root.$emit("panel:resized", panel.name);
        }
      },
      get() {
        let value =
          this.panel.heightProportion === ""
            ? 0
            : this.panel.heightProportion * 100;
        return value > 0 && value <= 100 ? Math.round(value) : "auto";
      }
    },
    fitToPage: {
      set(value) {
        let panel = JSON.parse(JSON.stringify(this.panel || null));
        if (panel) {
          panel.options.fitToPage = value;
          this.$set(panel, "options", panel.options);
          this.saveDraft(panel);
        }
      },
      get() {
        return this?.panel?.options?.fitToPage ?? false;
      }
    },
    minHeight: {
      set(value) {
        this.setPanelHeight("min-height", value);
        this.saveDraft(this.panel);
        this.$root.$emit("panel:resized", this.panel.name);
      },
      get() {
        return this.getPanelHeight("min-height");
      }
    },
    maxHeight: {
      set(value) {
        this.setPanelHeight("max-height", value);
        this.saveDraft(this.panel);
        this.$root.$emit("panel:resized", this.panel.name);
      },
      get() {
        return this.getPanelHeight("max-height");
      }
    },
    panelVerticalScroll: {
      set(value) {
        this.setPanelVerticalScroll(value);
        this.saveDraft(this.panel);
      },
      get() {
        let panel = this.panel || null; //fake
        if (panel) {
          //scroll|auto|hidden
          return (panel?.style || {})["overflow-y"] || "hidden";
        }
        return "";
      }
    },
    panelHorizontalScroll: {
      set(value) {
        this.setPanelHorizontalScroll(value);
        this.saveDraft(this.panel);
      },
      get() {
        let panel = this.panel || null; //fake
        if (panel) {
          //scroll|auto|hidden
          return (panel?.style || {})["overflow-x"] || "hidden";
        }
        return "";
      }
    },
    panelTitle: {
      set(value) {
        this.title = value;
      },
      get() {
        return this.title;
      }
    },
    panelIconEnabled: {
      set(value) {
        this.iconEnabled = value;
        if (value) {
          let dftIcon = defPanel(this.panel.template).template.icon || "";
          this.icon = this.panelIcon || dftIcon || "fa fa-flag";
        } else {
          this.icon = null;
        }
        return;
      },
      get() {
        return this.iconEnabled;
      }
    },
    panelIcon: {
      set(value) {
        this.icon = value;
      },
      get() {
        return this.icon;
      }
    },
    toolbarStyle: {
      set(value) {
        if (!this.panel) return;
        let panel = JSON.parse(JSON.stringify(this.panel || null));
        panel.toolbarStyle = value;
        this.saveDraft(panel);
      },
      get() {
        return this?.panel?.toolbarStyle ?? {};
      }
    },
    toolbarStyleFontSize: {
      set(value) {
        let toolbarStyle = this.toolbarStyle;
        if (value == "") toolbarStyle['font-size'] = "initial";
        else toolbarStyle['font-size'] = value+'pt';
        this.toolbarStyle = toolbarStyle;
      },
      get() {
        return onlyNumbers(this.toolbarStyle['font-size'] ?? "");
      }
    },
    panelIframe: {
      set(value) {
        this.iframeTitle = value;
      },
      get() {
        return this.iframeTitle;
      }
    },
    panelColor: {
      set(value) {
        let panel = JSON.parse(JSON.stringify(this.panel || null));
        if (panel) {
          let style = panel.style || {};
          style["background-color"] = value;
          panel.style = style;
          panel.options = this.panel.options; // it preserves detail observer
          this.saveDraft(panel);
        }
      },
      get() {
        let panel = this.panel || null;
        if (panel) {
          //scroll|auto|hidden
          return (panel?.style || {})["background-color"] || "#FFFFFF";
        }
        return "";
      }
    },
    panelProcessAreas: {
      set(value) {
        let panel = JSON.parse(JSON.stringify(this.panel));
        panel.view_permission = value;
        panel.options = this.panel.options; // it preserves detail observer
        this.saveDraft(panel);
      },
      get() {
        return this.panel.view_permission;
      }
    },
    screenId() {
      return this.$route.path.split("/")[4];
    },
    panelName() {
      return this.$route.path.split("/")[5];
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    panelStyle() {
      return this?.panel?.style || {};
    },
    customizableToolbar() {
      if (!this.panel) return false;
      return (
        Panels.find((panel) => panel.template.template == this.panel.template)
          ?.customizableToolbar || false
      );
    },
    template() {
      return this.$store.getters["dashboard/editorTemplate"] || null;
    },
    isLinkedPanel() {
      return isLinkedPanel(this.template, this.panelName);
    },
    isSyncEnabled() {
      return isSyncEnabled(this.template, this.panelName);
    },
    canEditProperties() {
      // it is now possible to edit global panel properties (such as title) even for external ones
      return true;
      // return !this.isLinkedPanel || !this.isSyncEnabled;
    },
    layout() {
      return (this.template && this.template.layout) || null;
    },
    position() {
      let info = {
        ri: -1, // row index
        ci: -1, // column index
        pi: -1, // panel index
        nc: -1, // number of columns in row
        np: -1 // number of panels in column
      };
      for (var ri in this.layout || []) {
        for (var ci in this.layout[ri] || []) {
          for (var pi in this.layout[ri][ci]?.panels || []) {
            if (this.layout[ri][ci].panels[pi] == this.panelName) {
              info = {
                ri: ri,
                ci: ci,
                pi: pi,
                nc: this.layout[ri].length,
                np: this.layout[ri][ci].panels.length
              };
            }
          }
        }
      }
      return info;
    },
    canFitOnPage() {
      return this.position.nc == 1 && this.position.np == 1;
    },
    canBeProportional() {
      return this.position.ci > 0;
    },
    isEditing() {
      return this.panelName !== undefined; // it might be possible route has already be changed
    },
    draft() {
      return this.$store.getters["dashboard/draft"];
    },
    connector() {
      let refMap = this?.draft?.template?.draft?.refMap || null;
      if (refMap && refMap.conn1) {
        return (this.$store.getters["dashboard/connectorList"] || []).find(
          ({id}) => parseInt(id) == parseInt(refMap.conn1)
        );
      }
      return null;
    },
    allowedJSONContext() {
      let entry = {system: this.$store.getters.systemProperties};
      if (this.connector) {
        entry.connector = this.connector;
      }
      return entry;
    },
    classTitleSize() {
      if (!this.canFitOnPage) return 'title-size';
      return 'larger-title-size';
    }
  },
  watch: {
    panelName: {
      handler(n, o) {
        if (n && this?.panel && this.isEditing) {
          // translate it only once
          this.panelTitle = (this?.panel && this?.panel?.title) || "";
          this.icon =
            this.panel && (this.panel.icon || this.panel.icon === null)
              ? this.panel.icon
              : "";
          this.iconEnabled = this.icon ? true : false;
          this.iframeTitle = (this?.panel && this?.panel?.iframeTitle) || false;
        }
        if (n && n != o) {
          this.ready = false;
        }
      },
      immediate: true
    },
    canFitOnPage(n, o) {
      if (!n && o && this.isEditing) {
        this.fitToPage = false;
      }
    },
    canBeProportional(n, o) {
      if (!n && o && this.isEditing) {
        this.proportionalHeight = "";
      }
    },
    panelTitle(n) {
      this.panel.title = n;
      this.saveDraft(this.panel);
    },
    panelIcon(n) {
      this.panel.icon = n;
      this.saveDraft(this.panel);
    },
    panelIframe(n) {
      this.panel.iframeTitle = n;
      this.saveDraft(this.panel);
    }
  },
  methods: {
    setPanelHeight(prop, value) {
      let style = JSON.parse(JSON.stringify(this?.panel?.style || {}));
      value = (value + "").replace(/px/gi, "");
      if (value === "" || value === "auto" || isNaN(Number(value))) {
        style[prop] = "auto";
      } else {
        style[prop] = (value + "").replace(/px/gi, "") + "px";
      }
      this.$set(this.panel, "style", style);
    },
    getPanelHeight(prop) {
      let value = ((this.panel?.style || {})[prop] || "").replace(/px/, "");
      return value === "" ? "auto" : value;
    },
    setPanelVerticalScroll(value) {
      let style = JSON.parse(JSON.stringify(this?.panel?.style || {}));
      style["overflow-y"] = value;
      this.$set(this.panel, "style", style);
    },
    setPanelHorizontalScroll(value) {
      let style = JSON.parse(JSON.stringify(this?.panel?.style || {}));
      style["overflow-x"] = value;
      this.$set(this.panel, "style", style);
    },
    saveDraft(panel) {
      this.$store.dispatch("dashboard/saveDraftPanel", {
        screenId: this.screenId,
        panelName: this.panelName,
        panel: panel,
        setAsCurrent: true
      });
    },
    onApply() {
      let template = this.template;
      let layout = (template && template.layout) || null;
      if (layout) {
        let self = this;
        for (var ixRow in layout) {
          let row = layout[ixRow];
          let lst = [];
          let found = false;
          for (var ixColumn in row || []) {
            let column = row[ixColumn];
            for (var ixPanel in column?.panels || []) {
              let panelName = column?.panels[ixPanel];
              if (panelName == self.panelName) {
                found = true;
              } else {
                lst.push({
                  name: panelName,
                  perc: 100 / column.panels.length / 100
                });
              }
            }
          }
          if (found) {
            let refMap = this.screenRefMap;
            if (!refMap || !refMap?.conn1) {
              let screen =
                this.$store.getters["dashboard/screen"](this.screenId) || null;
              if (screen?.reference_connectors?.length) {
                refMap = {conn1: screen?.reference_connectors[0].id};
              }
            }
            let connectorId = (refMap && refMap.conn1) || null;
            let connector =
              (connectorId &&
                this.$store.getters["equipmentById"](connectorId)) ||
              null;
            lst.forEach((item) => {
              var panelName = item.name;
              var height = self.proportionalHeight * item.perc;
              if (item.perc < 1) {
                height -= 5; // padding between panels is 10px
              }
              // search for an existing draft panel
              let panel = this.$store.getters["dashboard/draftPanel"]({
                screenId: self.screenId,
                panelName: panelName
              });
              if (!panel && connector) {
                // once there is no draft panel - it creates one based on the selected equipment
                let connectorPanel =
                  connector?.display?.panels?.find(
                    (item) => item.name == panelName
                  ) || null;
                if (connectorPanel) {
                  panel = JSON.parse(JSON.stringify(connectorPanel));
                }
              }
              if (panel) {
                self.setPanelHeight(panel, height);
                self.setPanelHorizontalScroll(self.panelHorizontalScroll);
                self.setPanelVerticalScroll(self.panelVerticalScroll);
                self.$store.dispatch("dashboard/saveDraftPanel", {
                  screenId: self.screenId,
                  panelName: panelName,
                  panel: panel,
                  setAsCurrent: false
                });
              }
            });
            break;
          }
        }
        if (!this.$store.getters["dashboard/expandedPanel"]) {
          this.$root.$emit("dashboard:refresh");
        }
      }
    },
    editGeneralPanelProperties() {
      this.$root.$emit("dashboard:editor", {
        action: "editPanel",
        data: {panelName: this.panelName, showContentProperties: false}
      });
    },
    editSpecificPanelProperties() {
      this.$root.$emit("dashboard:editor", {
        action: "editPanel",
        data: {panelName: this.panelName, showContentProperties: true}
      });
    },
    setContentPanelWidget(widget) {
      if (
        widget &&
        this?.contentPanelWidget &&
        widget.name == this.contentPanelWidget.name
      ) {
        this.ready = true;
        return;
      }
      this.$set(this, "contentPanelWidget", widget);
      let sidebar = this.$store.getters["dashboard/sidebar"] || null;
      if (sidebar) {
        sidebar.contentPanelWidget = widget ? widget.name : null;
        if (sidebar.contentPanelWidget) {
          this.$store.commit("dashboard/SET_SIDEBAR", sidebar);
        } else if (
          this.panel.template ==
          this.$store.getters["dashboard/defaultPanelConfiguration"].template
        ) {
          this.$root.$emit("drawer:event", {
            action: "tab:activate",
            details: "components"
          });
        }
      }
      this.ready = true;
    },
    resetContentPanelWidget() {
      if (!this.contentPanelWidget) return;
      let sidebar = this.$store.getters["dashboard/sidebar"] || null;
      if (
        sidebar &&
        sidebar.contentPanelWidget == this.contentPanelWidget.name
      ) {
        sidebar.contentPanelWidget = null;
        this.$store.commit("dashboard/SET_SIDEBAR", sidebar);
      }
    },
    resetTitle() {
      this.panelTitle = "";
      if (this.$refs.panelTitle) {
        this.$refs.panelTitle.focus();
      }
    }
  },
  created() {
    this.$root.$on(
      "controlSidebar:setContentPanelWidget",
      this.setContentPanelWidget
    );
  },
  beforeDestroy() {
    this.$root.$off(
      "controlSidebar:setContentPanelWidget",
      this.setContentPanelWidget
    );
    this.resetContentPanelWidget();
  }
};
</script>

<style scoped>
.me {
  margin-bottom: 10px;
  /* padding-inline: 15px; */
  position: relative;
  background: white;
}

.skin-dark .me {
  background-color: transparent;
}

.me .toggle-panel::v-deep .box-header {
  margin-inline: -15px;
}

.property {
  margin-bottom: 5px;
}

.property label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.03em;
}

.input-group-addon-scroll {
  min-width: 90px;
  padding: 0;
}

.btn-icon {
  min-width: 45px;
}
.new_button {
  margin-top: 10px;
}
.input-group-addon-select {
  padding: 0;
}
.input-group-addon-select > select {
  border: 0;
  font-size: 85%;
  background: #ffffff;
}
.input-group-addon-select > select:hover {
  cursor: pointer;
  opacity: 0.8;
}
.input-group-addon-select > select:focus {
  border: 0;
  outline-width: 0;
}

.control-header {
  padding: 5px 2px;
  font-weight: 600;
  background: #eee;
  border-radius: 3px;
  margin-top: 1px;
}
.control-box {
  margin: 0;
}
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
.control-icon {
  margin-left: 5px;
  min-width: 20px;
}
label > input[type="checkbox"] {
  vertical-align: text-bottom;
}

.title-size {
  width: 90px;
}
.larger-title-size {
  width: 110px;
}
.unit-size {
  width: 40px;
}
</style>
